<template>
  <div id="app">
    <BaseLayout />
  </div>
</template>
<script>
import BaseLayout from "@/components/BaseLayout";
export default {
  components: {
    BaseLayout
  }
};
</script>

<style lang="scss">
@import "@/styles/style.scss";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  box-sizing: border-box;
}
</style>
