<template>
  <div class="base-layout-container">
    <BaseMenu :menu-items="menuItems" />
    <main class="page-wrapper">
      <router-view></router-view>
    </main>
    <BaseFooter />
  </div>
</template>

<script>
import { BaseMenu, BaseFooter } from "@/components";
export default {
  name: "BaseLayout",
  data() {
    return {
      menuItems: [
        { label: "Team", routeName: "Team" },
        { label: "About", routeName: "About" },
        { label: "Output", routeName: "Research output" },
        { label: "Fact sheets", routeName: "Fact sheets" },
        { label: "News and more", routeName: "News and more" }
      ]
    };
  },
  components: {
    BaseMenu,
    BaseFooter
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/style.scss";
.page-wrapper {
  margin-top: 63px;
  @media (min-width: $tablet) {
    margin-top: 0;
  }
}
</style>
