<template>
  <div class="button-wrapper base-button">
    <a :class="[size, theme]" :href="link">
      <slot />
      <div class="icon">
        <b-icon :icon="icon" />
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "Button",
  props: {
    size: {
      type: String,
      default: "small"
    },
    icon: {
      type: String,
      default: "chevron-right"
    },
    theme: {
      type: String,
      default: "blue"
    },
    link: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/style.scss";
</style>
