<template>
  <div class="research-output">
    <LoadingSpinner v-if="!getResearchOutput" />
    <div v-else class="inner-content page">
      <TitleGroup text="Research output" />
      <div class="publications">
        <!-- <div v-html="nativeContent"/> -->
        <div v-for="(item, i) in publications" :key="i" class="publication">
          <div class="top-section">
            <div v-html="item.research_title" class="item-title" />
            <b-icon icon="caret-down-fill" v-b-toggle="`publication-${i}`" />
          </div>
          <b-collapse :id="`publication-${i}`">
            <hr />
            <div v-html="item.research_description" />
          </b-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LoadingSpinner, TitleGroup } from "@/components";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ResearchOutput",
  components: {
    LoadingSpinner,
    TitleGroup
  },
  computed: {
    ...mapGetters(["getResearchOutput", "getLoading"]),
    pageTitle() {
      return this.getResearchOutput.title;
    },
    publications() {
      return this.getResearchOutput.publications;
    },
    nativeContent() {
      return this.getResearchOutput.nativeContent;
    }
  },
  async created() {
    if (!this.getResearchOutput) {
      await this.fetchResearchOutput();
    }
  },
  methods: {
    ...mapActions(["fetchResearchOutput"])
  }
};
</script>

<style lang="scss">
@import "@/styles/style.scss";
.publications {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  .top-section {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-gap: 1rem;
    svg {
      color: $blue;
      align-self: center;
    }
  }
  .publication {
    background-color: rgba($color: $blue, $alpha: 0.1);
    padding: 1rem;
    border-radius: 1rem;
    width: 100%;
  }
}
</style>
