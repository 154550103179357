<template>
  <div class="loading-spinner-wrapper">
    <b-spinner></b-spinner>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner"
};
</script>

<style lang="scss">
.loading-spinner-wrapper {
  width: fit-content;
  margin: auto;
  padding: 2rem;
}
</style>
