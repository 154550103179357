<template>
  <div class="nav-wrapper">
    <b-navbar toggleable="md" class="nav inner-content">
      <b-navbar-brand @click="$router.push({ name: 'Home' })">
        <span>
          IntegrateYouth
        </span>
      </b-navbar-brand>
      <b-navbar-toggle target="navbar-toggle-collapse" class="btn-toggle">
        <template #default="{ expanded }">
          <b-icon v-if="expanded" icon="x" font-scale="1.5"></b-icon>
          <b-icon v-else icon="list" font-scale="1.5"></b-icon>
        </template>
      </b-navbar-toggle>
      <b-collapse id="navbar-toggle-collapse" class="nav-items" is-nav>
        <b-navbar-nav>
          <b-nav-item
            v-for="item in menuItems"
            :to="{ name: item.routeName }"
            :key="item.routeName"
          >
            <span>
              {{ item.label }}
            </span>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "BaseMenu",
  data() {
    return {
      minimize: true,
      lastScrollPosition: 0
    };
  },
  props: {
    menuItems: {
      type: Array,
      required: true
    }
  },
  computed: {
    isPhone() {
      return window.innerWidth < 576;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/style.scss";
* {
  color: white;
}
.nav-wrapper {
  background-color: $black;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  @media (min-width: $tablet) {
    position: unset;
  }
}
.navbar.navbar-expand-md {
  border-bottom: 1px solid black;
  padding: 0;
  background-color: $black;
  display: grid;
  grid-template-columns: 1fr min-content;
  .navbar-brand {
    padding: 1rem 0;
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0;
  }
  .btn-toggle {
    border: unset !important;
    padding: 1rem 0 !important;
    align-self: flex-end;

    &:hover {
      background-color: transparent !important;
    }
  }
  .nav-items {
    ul {
      padding: 1rem 0 2rem 0;
      font-size: 0.9rem;
    }
    .nav-link,
    a {
      padding-top: 1rem;
      padding-bottom: 0.5rem;
    }
    .router-link-exact-active {
      span {
        border-bottom: 2px solid $blue;
        font-weight: bold;
      }
    }
  }
}
@media (min-width: $tablet) {
  .navbar.navbar-expand-md {
    align-content: center;
    grid-template-columns: max-content 1fr;
    grid-gap: 4rem;
    height: 75px;
    .navbar-brand,
    .nav-items {
      ul {
        padding: 0 !important;
        background-color: transparent;
      }
    }
    .navbar-brand {
      padding: 0;
    }
    .nav-items {
      .nav-link {
        padding: 1rem;
        span {
          padding-bottom: 0.2rem;
        }
      }
    }
  }
}
</style>
