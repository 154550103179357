<template>
  <div class="logos-wrapper">
    <div class="inner-content">
      <h4>
        This project is funded by NordForsk Joint Nordic-UK research programme
        on Migration and Integration
      </h4>
      <div class="logo sofi">
        <img
          src="https://api.integrateyouth.org/wp-content/uploads/2021/03/SU_logo_LIGGANDE_DIGITALT_ANPASSAD_400px_ENG.png"
          alt=""
        />
      </div>
      <div class="logo fafo">
        <img
          src="https://api.integrateyouth.org/wp-content/uploads/2021/03/Meddelanden-bild2070569395.jpeg"
          alt=""
        />
      </div>
      <div class="logo nuffield">
        <img
          src="https://api.integrateyouth.org/wp-content/uploads/2021/03/nuffield.png"
          alt=""
        />
      </div>
      <div class="logo nordforsk">
        <img
          src="https://api.integrateyouth.org/wp-content/uploads/2021/03/nordforsk.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/styles/style.scss";
.logos-wrapper {
  background-color: rgba($color: $extra-light-gray, $alpha: 0.5);
  border-bottom: 1px solid $gray;
  .inner-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 3rem;
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    h4 {
      grid-column: 1/-1;
      color: $dark-gray;
    }
    .logo {
      height: fit-content;
      width: 100%;
      background-repeat: no-repeat;
      background-size: 100% auto;
      img {
        width: 100%;
      }
    }
  }
}
</style>
