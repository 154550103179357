<template>
  <div class="news-and-more">
    <LoadingSpinner v-if="getNews === null || getLinks === null" />
    <div class="inner-content page" v-else>
      <TitleGroup text="News and more" />
      <div class="news-wrapper items" v-if="getNews && getNews.length">
        <h3>News</h3>
        <div class="news-item item" v-for="(item, i) in getNews" :key="i">
          <div class="top-section">
            <div v-html="item.title" class="item-title" />
            <b-icon icon="caret-down-fill" v-b-toggle="`news-item-${i}`" />
          </div>
          <b-collapse :id="`news-item-${i}`">
            <hr />
            <p v-html="item.content" />

            <p>{{ formatDate(item.date) }}</p>
          </b-collapse>
        </div>
      </div>
      <div class="links-wrapper items" v-if="getLinks && getLinks.length">
        <h3>Links</h3>
        <div class="link item" v-for="(item, i) in getLinks" :key="i">
          <div class="top-section">
            <div v-html="item.title" class="item-title" />
            <b-icon icon="caret-down-fill" v-b-toggle="`link-item-${i}`" />
          </div>
          <b-collapse :id="`link-item-${i}`">
            <hr />
            <p v-html="item.content"></p>
          </b-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TitleGroup, LoadingSpinner } from "@/components";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NewsAndMore",
  components: {
    TitleGroup,
    LoadingSpinner
  },
  computed: {
    ...mapGetters(["getNews", "getLinks", "getLoading"])
  },
  async created() {
    await this.fetchNews();
    await this.fetchLinks();
  },
  methods: {
    ...mapActions(["fetchNews", "fetchLinks"]),
    formatDate(rawDate) {
      const date = new Date(rawDate);
      const month =
        date.getMonth() < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
      return `${date.getFullYear()}-${month}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/style.scss";
.items {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  padding: 1rem 0;
  .top-section {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-gap: 1rem;
    svg {
      color: $blue;
      align-self: center;
    }
  }
  .item {
    background-color: rgba($color: $blue, $alpha: 0.1);
    padding: 1rem;
    border-radius: 1rem;
    width: 100%;
  }
}
</style>
