<template>
  <div class="fact-sheets">
    <LoadingSpinner v-if="!getFactSheets" />
    <div class="inner-content page" v-else>
      <TitleGroup text="Fact sheets" />

      <div v-html="getFactSheets.content.rendered" />
      <div
        v-for="(item, i) in getFactSheets.fact_sheets"
        :key="i"
        class="fact-sheets-items"
      >
        <div class="top-section">
          <div v-html="item.fact_sheet_title" class="item-title" />
          <b-icon icon="caret-down-fill" v-b-toggle="`fact-sheet-${i}`" />
        </div>
        <b-collapse :id="`fact-sheet-${i}`">
          <hr />
          <div v-html="item.fact_sheet_description" />
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { LoadingSpinner, TitleGroup } from "@/components";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "FactSheets",
  components: {
    LoadingSpinner,
    TitleGroup
  },
  computed: {
    ...mapGetters(["getFactSheets", "getLoading"]),
    content() {
      return this.getFactSheets.content.rendered;
    }
  },
  async created() {
    if (!this.getFactSheets) {
      await this.fetchFactSheets();
    }
  },
  methods: {
    ...mapActions(["fetchFactSheets"])
  }
};
</script>

<style lang="scss">
@import "@/styles/style.scss";
</style>
