<template>
  <div class="about">
    <LoadingSpinner v-if="!getAboutPage" />
    <div class="about-content inner-content page" v-else>
      <!-- <div class="image-container">
        <b-img-lazy blank-color="#E5E5E5" :src="getGeneralImage" alt="" />
      </div> -->
      <TitleGroup text="About" class="my-4" />
      <div class="body-text" v-html="getAboutPage"></div>
    </div>
  </div>
</template>
<script>
import { LoadingSpinner, TitleGroup } from "@/components";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "About",
  components: {
    LoadingSpinner,
    TitleGroup
  },
  computed: {
    ...mapGetters(["getAboutPage", "getLoading", "getGeneralImage"])
  },
  async created() {
    await this.fetchHeaderImage();
    if (!this.getAboutPage) {
      await this.fetchAboutPageContent();
    }
  },
  methods: {
    ...mapActions(["fetchAboutPageContent", "fetchHeaderImage"])
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/style.scss";

.about {
  display: grid;
  grid-template-columns: 1fr;
}
.about-content {
  .image-container {
    img {
      width: 100%;
    }
  }
  .body-text {
    @media (min-width: $desktop) {
      column-count: 2;
    }
  }
}
</style>
