const axios = require("axios").default;
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: null,
    team: null,
    homePage: null,
    aboutPage: null,
    generalImage: null,
    researchOutput: null,
    factSheets: null,
    news: null,
    links: null
  },
  getters: {
    getLinks: state => {
      return state.links;
    },
    getNews: state => {
      return state.news;
    },
    getFactSheets: state => {
      return state.factSheets;
    },
    getResearchOutput: state => {
      return state.researchOutput;
    },
    getGeneralImage: state => {
      return state.generalImage;
    },
    getLoading: state => {
      return state.isLoading;
    },
    getTeam: state => {
      return state.team;
    },
    getHomePage: state => {
      return state.homePage;
    },
    getAboutPage: state => {
      return state.aboutPage;
    }
  },
  mutations: {
    SET_LINKS(state, payload) {
      Vue.set(state, "links", payload);
    },
    SET_NEWS(state, payload) {
      Vue.set(state, "news", payload);
    },
    SET_FACT_SHEETS(state, payload) {
      Vue.set(state, "factSheets", payload);
    },
    SET_RESEARCH_OUTPUT(state, payload) {
      Vue.set(state, "researchOutput", payload);
    },
    SET_GENERAL_IMAGE(state, payload) {
      Vue.set(state, "generalImage", payload);
    },
    SET_CONTENT_ABOUT_PAGE(state, payload) {
      Vue.set(state, "aboutPage", payload);
    },
    SET_CONTENT_HOMEPAGE(state, payload) {
      Vue.set(state, "homePage", payload);
    },
    SET_TEAM(state, payload) {
      Vue.set(state, "team", payload);
    },
    SET_LOADING(state, payload) {
      Vue.set(state, "isLoading", payload);
    }
  },
  actions: {
    fetchLinks: async ({ commit }) => {
      commit("SET_LOADING", true);
      return await axios
        .get("https://api.integrateyouth.org/wp-json/wp/v2/posts?categories=3")
        .then(({ data }) => {
          if (data) {
            commit(
              "SET_LINKS",
              data.map(post => {
                return {
                  title: post.title.rendered,
                  content: post.content.rendered,
                  date: post.date
                };
              })
            );
            commit("SET_LOADING", false);
          }
        })
        .catch(e => {
          console.error(e);
        });
    },
    fetchNews: async ({ commit }) => {
      commit("SET_LOADING", true);
      return await axios
        .get("https://api.integrateyouth.org/wp-json/wp/v2/posts?categories=2")
        .then(({ data }) => {
          if (data) {
            commit(
              "SET_NEWS",
              data.map(post => {
                return {
                  title: post.title.rendered,
                  content: post.content.rendered,
                  date: post.date
                };
              })
            );
            commit("SET_LOADING", false);
          }
        })
        .catch(e => {
          console.error(e);
        });
    },
    fetchFactSheets: async ({ commit }) => {
      commit("SET_LOADING", true);
      return await axios
        .get(
          "https://api.integrateyouth.org/wp-json/wp/v2/pages/73?_fields=fact_sheets,title,content"
        )
        .then(({ data }) => {
          if (data) {
            commit("SET_FACT_SHEETS", data);
            commit("SET_LOADING", false);
          }
        })
        .catch(e => {
          console.error(e);
        });
    },
    fetchResearchOutput: async ({ commit }) => {
      commit("SET_LOADING", true);
      return await axios
        .get(
          "https://api.integrateyouth.org/wp-json/wp/v2/pages/71?_fields=research_output,title,content"
        )
        .then(({ data }) => {
          if (data) {
            commit("SET_RESEARCH_OUTPUT", {
              publications: data.research_output,
              title: data.title.rendered,
              nativeContent: data.content.rendered
            });
            commit("SET_LOADING", false);
          }
        })
        .catch(e => {
          console.error(e);
        });
    },
    fetchPeople: async ({ commit }) => {
      commit("SET_LOADING", true);
      return await axios
        .get(
          "https://api.integrateyouth.org/wp-json/wp/v2/pages/5?_fields=crb_people"
        )
        .then(({ data }) => {
          if (data) {
            commit("SET_TEAM", data.crb_people);
            commit("SET_LOADING", false);
          }
        })
        .catch(e => {
          console.error(e);
        });
    },
    fetchHeaderImage: async ({ commit }) => {
      commit("SET_LOADING", true);
      return await axios
        .get(
          "https://api.integrateyouth.org/wp-json/wp/v2/media/15?_fields=guid"
        )
        .then(({ data }) => {
          commit("SET_GENERAL_IMAGE", data.guid.rendered);
          commit("SET_LOADING", false);
        })
        .catch(e => {
          commit("SET_LOADING", false);
          console.error(e);
        });
    },
    fetchHomepageContent: async ({ commit }) => {
      commit("SET_LOADING", true);
      return await axios
        .get(
          "https://api.integrateyouth.org/wp-json/wp/v2/pages/8?_fields=preamble,homepage_content,header_img"
        )
        .then(({ data }) => {
          commit("SET_CONTENT_HOMEPAGE", {
            preamble: data.preamble,
            content: data.homepage_content,
            headerImg: data.header_img
          });
          commit("SET_LOADING", false);
        })
        .catch(e => {
          commit("SET_LOADING", false);
          console.error(e);
        });
    },
    fetchAboutPageContent: async ({ commit }) => {
      commit("SET_LOADING", true);
      return await axios
        .get(
          "https://api.integrateyouth.org/wp-json/wp/v2/pages/31?_fields=about_content"
        )
        .then(({ data }) => {
          commit("SET_CONTENT_ABOUT_PAGE", data.about_content);
          commit("SET_LOADING", false);
        })
        .catch(e => {
          commit("SET_LOADING", false);
          console.error(e);
        });
    }
  }
  // modules: {}
});
