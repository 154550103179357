<template>
  <div class="home">
    <LoadingSpinner v-if="!getHomePage" />
    <div v-else>
      <div
        :class="['header-image']"
        :style="`background-image:url(${getHomePage.headerImg})`"
      />
      <div class="inner-content page">
        <div class="text">
          <div class="separator bold blue" />
          <div v-html="getHomePage.preamble" class="preamble" />
          <div v-html="getHomePage.content" class="body-text" />
        </div>
        <div class="cta">
          <div
            v-for="(item, i) in ctaItems"
            :key="i"
            @click="$router.push({ name: item.routeName })"
          >
            <p>{{ item.label }}</p>
            <b-icon icon="arrow-right-circle-fill" font-scale="1.5"></b-icon>
          </div>
        </div>
      </div>
    </div>
    <BaseActorLogos />
  </div>
</template>

<script>
import { LoadingSpinner, BaseActorLogos } from "@/components";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Home",
  components: {
    LoadingSpinner,
    BaseActorLogos
  },
  data() {
    return {
      ctaItems: [
        { label: "View our research", routeName: "Research output" },
        { label: "News and more", routeName: "News and more" }
      ]
    };
  },
  computed: {
    ...mapGetters(["getLoading", "getHomePage"])
  },
  async mounted() {
    if (!this.getHomePage) {
      await this.fetchHomepageContent();
    }
  },
  methods: {
    ...mapActions(["fetchHomepageContent"])
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/style.scss";

.home {
  display: grid;
  grid-template-columns: 1fr;
}
.header-image {
  width: 100%;
  height: 250px;
  background-color: $blue;
  background-blend-mode: luminosity;
  background-size: cover;
  background-position: 50% 67%;
  z-index: 1;
}
.inner-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
  @media (min-width: $desktop) {
    grid-template-columns: 1fr 15rem;
  }
  .text {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1rem;
    background-color: rgba($color: $blue, $alpha: 0.1);
    padding: 1rem;
    border-radius: 1rem;
    @media (min-width: $phone) {
      padding: 2rem;
    }
    .separator {
      grid-row: 1/-1;
    }
    .preamble {
      align-self: flex-end;
    }
    .body-text {
      align-self: flex-start;
    }
  }
  .cta {
    display: flex;
    justify-content: center;
    align-content: center;
    align-self: center;
    grid-gap: 1rem;
    width: 100%;
    flex-wrap: wrap;
    div {
      display: grid;
      grid-template-rows: repeat(2, min-content);
      grid-gap: 1rem;
      font-size: 1.2rem;
      width: 15rem;
      border-radius: 5rem;
      height: 7rem;
      justify-items: center;
      align-content: center;
      font-weight: bold;
      background-color: rgba($color: $dark-blue, $alpha: 0.9);
      color: white;
      text-align: center;
      transition: $b-color-transition;
      &:hover {
        background-color: rgba($color: $dark-blue, $alpha: 1);
      }
      p {
        margin: 0;
      }
    }
  }
}
</style>
