<template>
  <div class="title-group">
    <div class="separator bold blue" />
    <h2>{{ text }}</h2>
  </div>
</template>

<script>
export default {
  name: "TitleGroup",
  props: {
    text: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.title-group {
  display: grid;
  grid-template-columns: min-content 1fr;
  margin: 1rem 0;
  grid-gap: 2rem;
  height: fit-content;
}
</style>
