<template>
  <div class="team">
    <LoadingSpinner v-if="!getTeam" />
    <div class="team-content inner-content page" v-else>
      <TitleGroup text="Team" />
      <div class="people">
        <div v-for="(person, i) in getTeam" :key="i" class="person">
          <div class="img-container">
            <div
              v-if="person.description"
              class="icon-container"
              v-b-toggle="`description-container-${i}`"
            >
              <b-icon class="b-icon" icon="info-circle-fill" font-scale="1.5" />
            </div>
            <b-img-lazy
              :src="person.profile_picture"
              class="profile-picture"
              blank-color="#E5E5E5"
            />
            <b-collapse
              :id="`description-container-${i}`"
              class="description-container"
            >
              <p v-html="person.description" class="description"></p>
            </b-collapse>
          </div>
          <div class="person-name">
            <h5>{{ person.name }}</h5>
            <p>
              <i>{{ person.person_role }}</i>
            </p>
            <Button
              class="website-btn"
              theme="link"
              :link="person.link_to_website"
              >Website</Button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LoadingSpinner, TitleGroup, Button } from "@/components";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Team",
  components: {
    LoadingSpinner,
    TitleGroup,
    Button
  },
  data() {
    return {
      showInfo: false
    };
  },
  computed: {
    ...mapGetters(["getLoading", "getTeam"])
  },
  async created() {
    if (!this.getTeam) {
      await this.fetchPeople();
    }
  },
  methods: {
    ...mapActions(["fetchPeople"])
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/style.scss";
.team {
  display: grid;
}
.title-group {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  grid-gap: 2rem;
  height: fit-content;
}
.people {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  @media (min-width: $phone) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: $desktop) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: $desktop-xl) {
    grid-template-columns: repeat(4, 1fr);
  }
}
.person {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, min-content);
  row-gap: 1rem;
  background: $extra-light-gray;
  padding: 0.5rem;
  position: relative;
  width: 100%;
  height: fit-content;
  .person-name {
    padding: 1rem;
    h5 {
      margin: 0;
      padding: 0;
      font-size: 1.1rem;
    }
    p {
      margin: 0.5rem 0;
      font-size: 0.9rem;
    }
  }
  .website-btn {
    width: fit-content;
  }
  .description-container {
    position: absolute;
    width: 100%;
    p {
      overflow-y: scroll;
      height: 20rem;
      padding: 1rem;
      margin: 0;
      background-color: rgba(255, 255, 255, 0.9);
      font-size: 0.9rem;
      @media (min-width: $phone) {
        height: 15rem;
      }
    }
  }
  .img-container {
    height: 20rem;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    @media (min-width: $phone) {
      height: 15rem;
    }
    .icon-container {
      background-color: rgba($color: white, $alpha: 0.7);
      display: grid;
      justify-items: center;
      align-items: center;
      border-radius: 50%;
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      z-index: 99;
      width: 2rem;
      height: 2rem;

      .b-icon {
        color: $blue;
      }
      &:focus {
        outline: none;
      }
    }
    img {
      object-fit: cover;
      object-position: 45%;
      position: absolute;
      width: 100%;
      height: 100%;
      // &:hover {
      //   background-color: rgba($color: #000000, $alpha: 0);
      // }
    }
  }
}
</style>
