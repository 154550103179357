import Vue from "vue";
import VueRouter from "vue-router";
import {
  Home,
  Team,
  About,
  ResearchOutput,
  FactSheets,
  NewsAndMore
} from "@/views";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/team",
    name: "Team",
    component: Team
  },
  {
    path: "/research-output",
    name: "Research output",
    component: ResearchOutput
  },
  {
    path: "/fact-sheets",
    name: "Fact sheets",
    component: FactSheets
  },
  {
    path: "/news-and-more",
    name: "News and more",
    component: NewsAndMore
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
